<script>
import DefaultMixin from '../mixin'

export default {
  name: 'FenajuDashboard',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
  },
  data() {
    return {
      menuActive: 'suporte'
    }
  },
  computed: {
  },
  beforeMount () {
  },
  watch: {
  },
  mounted() {
    document.body.classList.add('console-clientes-dashboard')
  },
  destroyed() {
    document.body.classList.remove('console-clientes-dashboard')
  },
  methods: {
  },
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
    <div class="console-dashboard">
      <router-view />
    </div>
</template>

<style lang="stylus">
.console-dash-menu.maxWidth {
  max-width 1440px
}
</style>
